<template>
  <div id="guest-form">
    <!-- INFO PERSONAL -->
    <b-row class="mb-1">
      <b-col cols="12" class="mb-1">
        <h4>Informació personal</h4>
      </b-col>
      <!-- FIRST NAME -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Nom</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="newGuest.firstName" placeholder="Nom de l'hoste" />
          </b-col>
        </b-row>
      </b-col>
      <!-- LAST NAME -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Cognoms</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="newGuest.lastName" placeholder="Cognom/s de l'hoste" />
          </b-col>
        </b-row>
      </b-col>
      <!-- BIRTHDATE -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Naixement</h5>
          </b-col>
          <b-col cols="12">
            <b-datepicker v-model="newGuest.birthDate" :max="new Date()" />
          </b-col>
        </b-row>
      </b-col>
      <!-- BIRTHDATE -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Parentiu</h5>
          </b-col>
          <b-col cols="12">
            <v-select v-model="newGuest.parentage" placeholder="Relació de parentiu" :options="parentageTypeOptions"
              :reduce="(option) => option.value" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- CONTACTE -->
    <b-row class="mb-1">
      <b-col cols="12" class="mb-1">
        <h4>Informació de contacte <small>(Obligatori un dels dos)</small></h4>
      </b-col>
      <!-- EMAIL -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Email</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="newGuest.email" placeholder="Email de l'hoste" />
          </b-col>
        </b-row>
      </b-col>
      <!-- PHONE -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Telèfon</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="newGuest.phone" placeholder="Telèfon de l'hoste" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- ADREÇA -->
    <b-row class="mb-1">
      <b-col cols="12" class="mb-1">
        <h4>Informació de residència</h4>
      </b-col>
      <!-- COUNTRY -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>País</h5>
          </b-col>
          <b-col cols="12">
            <v-select v-model="newGuest.addressCountry" placeholder="País" :options="countryOptionsAlpha3"
              :reduce="(option) => option.value" />
          </b-col>
        </b-row>
      </b-col>
      <!-- ZIP -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Codi Postal</h5>
          </b-col>
          <b-col cols="12">
            <v-select v-if="newGuest.addressCountry === 'ESP'" v-model="newGuest.addressZipInput"
              placeholder="Codi postal" :filterable="false" :options="spainCityZipOptions" @input="autoFillCityInfo"
              @search="onSearchCityZip" label="zipCode" :disabled="!newGuest.addressCountry">
              <template slot="no-options"> Escriu un Codi Postal </template>
              <template slot="option" slot-scope="option">
                <div>
                  <span>{{ option.zipCode }}</span>
                  <span class="ml-1">{{ option.cityName }}</span>
                </div>
              </template>
            </v-select>
            <b-form-input v-else v-model="newGuest.addressZip" placeholder="Codi postal" :disabled="!newGuest.addressCountry"  />
          </b-col>
        </b-row>
      </b-col>
      <!-- CIUTAT -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Ciutat</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="newGuest.addressCityName" placeholder="Ciutat" :disabled="!newGuest.addressCountry"/>
          </b-col>
        </b-row>
      </b-col>
      <!-- ADREÇA I NÚMERO -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Adreça</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="newGuest.address" placeholder="Adreça de l'hoste" :disabled="!newGuest.addressCountry" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- IDENTIFICACIÓ -->
    <template v-if="newGuestAge >= 18">
      <b-row class="mb-1">
        <b-col cols="12" class="mb-1">
          <h4>Identificació</h4>
        </b-col>
        <!-- ID TYPE -->
        <b-col cols="12">
          <b-row class="mb-2">
            <b-col cols="12">
              <h5>Tipus d'ID</h5>
            </b-col>
            <b-col cols="12">
              <v-select v-model="newGuest.idType" placeholder="Tipus d'ID" :options="idTypeOptions"
                :reduce="(option) => option.value" />
            </b-col>
          </b-row>
        </b-col>
        <!-- ID NUMBER -->
        <b-col cols="12" :sm="newGuest.idType === 'NIF' ? 6 : 12">
          <b-row class="mb-2">
            <b-col cols="12">
              <h5>Número</h5>
            </b-col>
            <b-col cols="12">
              <b-form-input v-model="newGuest.idNumber" placeholder="Nº de document" />
            </b-col>
          </b-row>
        </b-col>
        <!-- ID SUPPORT NUMBER -->
        <b-col v-if="newGuest.idType === 'NIF' || newGuest.idType === 'NIE'" cols="12" sm="6">
          <b-row class="mb-2">
            <b-col cols="12">
              <h5>Nº Soport</h5>
            </b-col>
            <b-col cols="12">
              <b-form-input v-model="newGuest.idSupportNumber" placeholder="Nº de Soport" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>

    <!-- SIGNATURE -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h4>
          Signatura
          <small class="cursor-pointer text-primary ml-25" @click="clearSignature()">
            esborra
          </small>
        </h4>
      </b-col>
      <b-col v-if="guest && newGuest.signature" cols="12">
        <b-img v-if="newGuest.signature" class="guest-signature mb-1" :src="newGuest.signature" alt="" fluid center />
      </b-col>
      <b-col v-else cols="12">
        <vue-signature-pad id="signature-pad" ref="signaturePad" width="100%" height="250px" class="mb-1 cursor-pointer"
          :options="signaturePadOptions" />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BImg,
} from "bootstrap-vue";
import { countryOptionsAlpha3, idTypeOptions, parentageTypeOptions } from "@/utils/select-options";
import vSelect from "vue-select";
import VueSignaturePad from "vue-signature-pad";
import BDatepicker from "@foravila-core/components/b-datepicker/BDatepicker.vue";
import axios from "@/axios";
import _debounce from "lodash/debounce";

export default {
  name: "GuestForm",
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    vSelect,
    VueSignaturePad,
    BImg,
    BDatepicker,
  },
  props: {
    guest: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      newGuest: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        birthDate: null,
        idType: null,
        idNumber: null,
        idSupportNumber: null,
        address: null,
        addressCountry: null,
        addressZip: null,
        addressZipInput: null,
        addressCityCode: null,
        addressCityName: null,
        parentage: null,
        signature: null,
      },
      spainCityZipOptions: [],
      signaturePadOptions: {
        penColor: "#000",
        onBegin: () => {
          if (this.$refs.signaturePad.isEmpty())
            this.$refs.signaturePad.resizeCanvas();
        },
      },
      datepickerOptions: {
        date: true,
        delimiter: "/",
        datePattern: ["d", "m", "Y"],
      },
      countryOptionsAlpha3,
      parentageTypeOptions
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    idTypeOptions() {
      return idTypeOptions.filter((option) => option.value !== "CIF" && option.value !== "DNI");
    },
    locale() {
      return this.$i18n.locale;
    },
    newGuestAge() {
      if (!this.newGuest.birthDate) return null;
      const birthDate = new Date(this.newGuest.birthDate);
      const ageDifMs = Date.now() - birthDate.getTime();
      const ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
  },
  created() {
    if (this.guest) this.init();
  },
  methods: {
    init() {
      this.newGuest = {
        firstName: this.guest.firstName,
        lastName: this.guest.lastName,
        email: this.guest.email || this.booking.email,
        phone: this.guest.phone,
        birthDate: this.guest.birthDate,
        idType: this.guest.idType,
        idNumber: this.guest.idNumber,
        idSupportNumber: this.guest.idSupportNumber,
        address: this.guest.address,
        addressCountry: this.guest.addressCountry,
        addressZip: this.guest.addressZip,
        addressZipInput: this.guest.addressZip,
        addressCityCode: this.guest.addressCityCode,
        addressCityName: this.guest.addressCityName,
        parentage: this.guest.parentage,
        signature: this.guest.signature,
      };
    },
    reset() {
      this.newGuest = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        birthDate: null,
        idType: null,
        idNumber: null,
        idSupportNumber: null,
        address: null,
        addressCountry: null,
        addressZip: null,
        addressZipInput: null,
        addressCityCode: null,
        addressCityName: null,
        parentage: null,
        signature: null,
      };
      this.clearSignature();
    },
    cancel() {
      this.$emit("cancel");
    },
    clearSignature() {
      if (this.$refs.signaturePad) this.$refs.signaturePad.clearSignature();
      this.newGuest.signature = null;
    },
    save() {
      if (this.guest) this.editGuest();
      else this.createGuest();
    },
    createGuest() {
      this.$store.dispatch("app/setLoading", true);

      if (this.$refs.signaturePad) {
        const { data } = this.$refs.signaturePad.saveSignature();
        this.newGuest.signature = data || null;
      }

      setTimeout(() => {
        this.$store
          .dispatch("booking/addGuest", {
            booking: this.booking["@id"],
            ...this.newGuest,
            confirmed: !!this.newGuest.signature,
            confirmedDate: this.newGuest.signature ? new Date() : null,
          })
          .then(() => this.$emit("guest-added"))
          .catch(() => {
            this.$emit("add-guest-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    editGuest() {
      this.$store.dispatch("app/setLoading", true);

      if (this.$refs.signaturePad) {
        const { data } = this.$refs.signaturePad.saveSignature();
        this.newGuest.signature = data || null;
      }

      setTimeout(() => {
        this.$store
          .dispatch("booking/updateGuest", {
            uuid: this.guest.uuid,
            ...this.newGuest,
            confirmed: !!this.newGuest.signature,
            confirmedDate: this.newGuest.signature ? new Date() : null,
          })
          .then(() => this.$emit("guest-edited"))
          .catch(() => {
            this.$emit("edit-guest-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    onSearchCityZip(search, loading) {
      if (search.length) {
        loading(true);
        this.fetchSpainCityZipOptions(search, loading, this);
      }
    },
    fetchSpainCityZipOptions: _debounce((search, loading, innerThis) => {
      axios
        .get(`/spain_zip_codes?zipCode=${search}`)
        .then((response) => {
          const results = response.data["hydra:member"] || [];
          innerThis.spainCityZipOptions = results.sort((a, b) =>
            a.zipCode.localeCompare(b.zipCode)
          );
        })
        .catch(() => {
          innerThis.spainCityZipOptions = [];
        })
        .finally(() => loading(false));
    }, 1000),
    async autoFillCityInfo(spainCity) {
      this.newGuest.addressZip = spainCity?.zipCode || null;
      this.newGuest.addressCityCode = spainCity?.cityCode || null;
      this.newGuest.addressCityName = spainCity?.cityName || null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#guest-form {
  .guest-signature {
    max-width: 250px;
  }

  #signature-pad {
    border: solid 1px #d8d6de;
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
}
</style>
