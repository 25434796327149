<template>
  <b-card title="Accions">
    <b-row>
      <!-- ONLINE CHECK-IN REQUEST -->
      <b-col class="mb-1" cols="12">
        <b-button
          block
          variant="primary"
          @click="clientOnlineCheckinRequestButtonClicked"
        >
          Sol·licita check-in online
        </b-button>
        <b-modal
          id="action-send-client-online-checkin-request-modal"
          title="Sol·licita el check-in online al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="online-checkin-request"
            @sent="onClientOnlineCheckinRequestSent"
            @send-error="onClientOnlineCheckinRequestSendError"
          />
        </b-modal>
      </b-col>

      <!-- GUESTS REPORT GENERATION -->
      <b-col class="mb-1" cols="12">
        <b-button
          block
          variant="primary"
          :disabled="!canGenerateGuestsReport"
          @click="generateReport"
        >
          Genera parte de viatgers
        </b-button>
      </b-col>

      <!-- POLICE REPORT GENERATION -->
      <b-col class="mb-1" cols="12">
        <b-button
          block
          variant="primary"
          :disabled="!canGeneratePoliceReport"
          @click="generatePoliceReport"
        >
          Genera fitxer de la policia
        </b-button>
      </b-col>

      <!-- SEND OWNER GUESTS REPORT -->
      <b-col class="mb-1" cols="12">
        <b-button
          block
          variant="primary"
          :disabled="!canSendGuestsReport"
          @click="ownerGuestsReportButtonClicked"
        >
          Envia el parte al propietari
        </b-button>
        <b-modal
          id="action-send-owner-guests-report-modal"
          title="Envia el parte de viatgers al propietari"
          hide-footer
          scrollable
          size="lg"
        >
          <owner-email-notification-form
            notification-type="guests-report"
            @sent="onOwnerGuestsReportSent"
            @send-error="onOwnerGuestsReportSendError"
          />
        </b-modal>
      </b-col>

      <!-- SEND HOSPEDAJES GUESTS REPORT -->
      <b-col class="mb-1" cols="12">
        <b-button
          block
          variant="primary"
          :disabled="!canSendGuestsReport"
          @click="hospedajesGuestsReportButtonClicked"
        >
          Envia el parte a hospedajes
        </b-button>
        <b-modal
          id="action-send-hospedajes-guests-report-modal"
          title="Envia el parte de viatgers a hospedajes"
          hide-footer
          scrollable
          size="lg"
        >
          <ul>
            <li>
              <b>Estat de l'enviament</b>:
              {{ hospedajesReport.sent ? "Enviat" : "No enviat" }}
              <span
                class="text-primary cursor-pointer"
                @click="sendCommunication"
              >
                {{ hospedajesReport.sent ? "Torna a envar" : "Envia" }}
              </span>
            </li>
            <li v-if="hospedajesReport.sent">
              <b>Data d'enviament</b>:
              {{ hospedajesReport.sentDate }}
            </li>
            <li v-if="hospedajesReport.sent">
              <b>Estat de la comunicació</b>:
              {{ hospedajesReport.communicationStatus }}
              <span
                class="text-primary cursor-pointer"
                @click="checkCommunicationStatus"
              >
                Comprova estat
              </span>
            </li>
            <li v-if="hospedajesReport.communicationId">
              <b>ID de comunicació</b>: {{ hospedajesReport.communicationId }}
            </li>
            <li v-if="hospedajesReport.error">
              <b>Error</b>: {{ hospedajesReport.errorMessage }}
            </li>
          </ul>
        </b-modal>
      </b-col>
    </b-row>

    <!-- GUESTS PDF -->
    <vue-html2pdf
      v-if="guests && accommodation"
      ref="html2Pdf"
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="794px"
      @progress="onProgress($event)"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <section
          v-for="guest in reportableGuests"
          :key="`guest-${guest.uuid}`"
          class="pdf-item"
        >
          <guest-report
            :guest="guest"
            :accommodation="accommodation"
            :booking="booking"
          />
          <div class="html2pdf__page-break" />
        </section>
      </section>
    </vue-html2pdf>
  </b-card>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import GuestReport from "@/views/bookings/booking/components/checkin/GuestReport.vue";
import { saveAs } from "file-saver";
import ClientEmailNotificationForm from "@/views/bookings/booking/components/notifications/ClientEmailNotificationForm.vue";
import OwnerEmailNotificationForm from "@/views/bookings/booking/components/notifications/OwnerEmailNotificationForm.vue";

import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import {
  formatDateObjectToYmdDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import {
  getAccommodationPoliceReportText,
  getGuestPoliceReportText,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import axios from "@/axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    VueHtml2pdf,
    GuestReport,
    ClientEmailNotificationForm,
    OwnerEmailNotificationForm,
  },
  data() {
    return {
      hospedajesReport: {
        sent: false,
        sentDate: null,
        communicationStatus: null,
        communicationId: null,
        error: false,
        errorMessage: null,
      },
    };
  },
  computed: {
    loadingBooking() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loadingAccommodation() {
      return this.$store.getters["accommodation/loadingAccommodation"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    loadingContract() {
      return this.$store.getters["accommodation/loadingContract"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    loadingGuests() {
      return this.$store.getters["booking/loadingGuests"];
    },
    guests() {
      return this.$store.getters["booking/guests"];
    },
    reportableGuests() {
      return this.$store.getters["booking/reportableGuests"];
    },
    loadingStats() {
      return this.$store.getters["booking/loadingStats"];
    },
    stats() {
      return this.$store.getters["booking/stats"];
    },
    policeReportText() {
      if (this.reportableGuests.length === 0) return null;
      const text = [];
      // Firs line, accommodation info
      text.push(
        getAccommodationPoliceReportText({
          policeRegistrationName: this.accommodation?.policeRegistrationName,
          policeRegistrationNumber:
            this.accommodation?.policeRegistrationNumber,
          guestsAmount: this.reportableGuests.length,
        })
      );
      // Rest of the lines, one per guest
      this.reportableGuests.forEach((guest) => {
        text.push(
          getGuestPoliceReportText({
            guest,
            checkin: formatDateObjectToYmdDate(new Date(this.booking.checkin)),
          })
        );
      });
      return text.join("\n");
    },
    owner() {
      return this.$store.getters["booking/owner"];
    },
    ownerHasEmail() {
      return !!this.owner?.email || false;
    },
    canGenerateGuestsReport() {
      return this.guests?.length;
    },
    canGeneratePoliceReport() {
      return (
        this.guests?.length &&
        this.accommodation?.policeRegistrationNumber &&
        this.accommodation?.policeRegistrationName
      );
    },
    canSendGuestsReport() {
      return this.ownerHasEmail && this.guests?.length && !this.loadingStats;
    },
  },
  watch: {
    stats() {
      this.initHospedajesReport();
    },
  },
  methods: {
    /*
      Generate Report using refs and calling the
      refs function generatePdf()
    */
    generateReport() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 100);
    },
    generatePoliceReport() {
      this.$store.dispatch("app/setLoading", true);
      const filename =
        this.accommodation?.policeRegistrationNumber || "Parte policia";
      const blob = new Blob([this.policeReportText], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, `${filename}.001`);
      this.$store.dispatch("app/setLoading", false);
    },
    onProgress(percentage) {
      if (percentage === 100) this.$store.dispatch("app/setLoading", false);
    },
    ownerGuestsReportButtonClicked() {
      this.$bvModal.show("action-send-owner-guests-report-modal");
    },
    hospedajesGuestsReportButtonClicked() {
      this.initHospedajesReport();
      this.$bvModal.show("action-send-hospedajes-guests-report-modal");
    },
    onOwnerGuestsReportSent() {
      this.$bvModal.hide("action-send-owner-guests-report-modal");
      notifySuccess(
        "Parte enviat",
        "El parte de viatgers ha estat enviat correctament al propietari"
      );
    },
    onOwnerGuestsReportSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar el parte de viatgers al propietari, contacta amb en Pedro"
      );
    },
    clientOnlineCheckinRequestButtonClicked() {
      this.$bvModal.show("action-send-client-online-checkin-request-modal");
    },
    onClientOnlineCheckinRequestSent() {
      this.$bvModal.hide("action-send-client-online-checkin-request-modal");
      notifySuccess(
        "Sol·licitut enviada",
        "La sol·licitut de check-in online ha estat enviada correctament al client"
      );
    },
    onClientOnlineCheckinRequestSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la sol·licitut de check-in online al client, contacta amb en Pedro"
      );
    },
    initHospedajesReport() {
      this.hospedajesReport = {
        sent: this.stats?.hospedajesReportSent || false,
        sentDate: this.stats?.hospedajesReportSentDate
          ? formatDateStringToDate(this.stats?.hospedajesReportSentDate)
          : null,
        communicationStatus: this.stats?.hospedajesReportCommunicationStatus,
        communicationId: this.stats?.hospedajesReportCommunicationId || null,
        error: this.stats?.hospedajesReportError || false,
        errorMessage: this.stats?.hospedajesReportErrorMessage || null,
      };
    },
    sendCommunication() {
      this.$store.dispatch("app/setLoading", true);
      axios
        .post("/hospedajes/parte-viajeros", {
          // INFO: hospedajesUser and hospedajesPassword no son necessaris perquè els recupera de l'allotjament de la bbdd: booking->accommodation
          localizator: this.booking?.localizator,
        })
        .then((response) => {
          console.log(response);
          if (response.data.codigo === 0) {
            this.hospedajesReport.sent = true;
            this.hospedajesReport.sentDate = new Date();
            this.hospedajesReport.communicationId = response.data.lote;
          } else {
            this.hospedajesReport.sent = false;
            this.hospedajesReport.error = true;
            this.hospedajesReport.errorMessage = response.data.descripcion;
          }
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar enviar el parte de viatgers a hospedajes, contacta amb en Pedro"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    checkCommunicationStatus() {
      this.$store.dispatch("app/setLoading", true);
      axios
        .post("/hospedajes/consulta-lote", {
          // TODO: recuperar hospedajesUser and hospedajesPassword de l'allotjament de la bbdd: booking->accommodation
          hospedajesUser: this.accommodation?.hospedajesUser || null,
          hospedajesPassword: this.accommodation?.hospedajesPassword || null,
          lote: this.hospedajesReport.communicationId,
        })
        .then((response) => {
          if (response.data.codigoEstado === 1) {
            this.hospedajesReport.communicationStatus = "OK";
            this.hospedajesReport.error = false;
            this.hospedajesReport.errorMessage = null;
          } else if ([4, 5].includes(response.data.codigoEstado)) {
            this.hospedajesReport.communicationStatus = "PENDING";
            this.hospedajesReport.error = false;
            this.hospedajesReport.errorMessage = null;
          } else {
            this.hospedajesReport.communicationStatus = "ERROR";
            this.hospedajesReport.error = true;
            this.hospedajesReport.errorMessage = response.data.errores;
          }
        })
        .catch((error) => console.error(error))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
